import { getInstance } from "./auth0-plugin";
import { isAdmin, isUserSW } from "@/utils/user";

export const authenticationGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      if (!checkUserSW(authService.user, to)) {
        return;
      } else {
        return next();
      }
    }
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      organization: process.env.VUE_APP_AUTH0_ORGANIZATION,
    });
  };

  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};

export const authenticationAdminGuard = (to, from, next) => {
  const authService = getInstance();

  const guardAction = () => {
    if (authService.isAuthenticated) {
      if (!checkUserSW(authService.user, to)) {
        return;
      }
      // check admin
      if (!isAdmin(authService.user)) {
        return next({
          name: "notAllowed",
        });
      }
      return next();
    }
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      organization: process.env.VUE_APP_AUTH0_ORGANIZATION,
    });
  };

  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};

function checkUserSW(authService, to) {
  if (!isUserSW(authService)) {
    authService.logout();
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
      organization: process.env.VUE_APP_AUTH0_ORGANIZATION,
    });
    return false;
  }
  return true;
}
