import { postRequest } from "./utility";
import { getInstance } from "../auth/auth0-plugin";

/**
 * @deprecated
 */
export function login(element, username, password) {
  const url = process.env.VUE_APP_URL_LOGIN;

  return element
    .axios({
      method: "POST",
      url: url,
      data: { username, password },
    })
    .then((response) => {
      var user = {
        firstname: "FirstName",
        lastname: "LastName",
        role: "ROLE",
        token: response.data.token,
      };
      return user;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Validate the account of the user.
 */
export function validateAccount(element, email, password) {
  const url = process.env.VUE_APP_URL_VALIDATION;
  const data = { email, password };
  const successCallback = () => {
    element.$router.push({ name: "home" });
  };
  const errorCallback = (e) => {
    console.error(e);
    element.$router.push({ name: "notAllowed" });
  };
  postRequest(element.axios, url, data, successCallback, errorCallback);
}

/**
 * Gets the role of the user
 */
export function getRole(user) {
  const key = process.env.VUE_APP_AUTH0_NAMESPACE + "/role";
  return user[key];
}

/**
 * Check if the user has the rights of an admin
 */
export function isAdmin(user) {
  if (!user) {
    user = getUser();
  }
  return user ? getRole(user) == "ADMIN" : false;
}

/**
 * Check if the user has the rights of an operator
 */
export function isOperator(user) {
  if (!user) {
    user = getUser();
  }
  const availabledRoles = ["ADMIN", "OPERATOR"];
  return user ? availabledRoles.indexOf(getRole(user)) >= 0 : false;
}

/**
 * Check if the user has the rights of a SW user
 */
export function isUserSW(user) {
  const availabledRoles = ["ADMIN", "USER"];
  const role = getRole(user);
  return availabledRoles.indexOf(role) >= 0;
}

/**
 * Gets the authenticate user
 */
export function getUser() {
  const authService = getInstance();
  return authService.user;
}
